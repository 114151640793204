import axios from "@/plugins/axios";
import useFilter from "@/store/useFilter";
import { createMonthType, createYearsType } from "@/types/DataTarget";
import { useMutation, useQuery } from "@tanstack/react-query";

export function List() {
  const { filter } = useFilter();

  return useQuery(["data-target"], async () => {
    return await axios("/admin/master/data-target", {
      params: {
        years: filter.years,
      },
    });
  });
}

export function CreateYears() {
  return useMutation(async (query: createYearsType) => {
    return await axios.post("/admin/master/target-data/year", [query]);
  });
}
export function CreateMonth() {
  return useMutation(async (query: createMonthType) => {
    return await axios.post("/admin/master/target-data/month", [query]);
  });
}
