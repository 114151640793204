import axios from "@/plugins/axios";
import { DashboardResume } from "@/types/Dashboard";
import { useQuery } from "@tanstack/react-query";


export const useGetDashboardResume = () => {

    return useQuery(["dashboard-resume"], () =>
        axios<DashboardResume>(
            `/admin/dashboard/resume`,
        )
    );
};

